import Directory from "../category-directory/category-directory.component";
import FeaturedImages from "./featured";

// A list of the featured projects shown on the Home page

const FeaturedList = () => {
  // const categories = [
  // 	{
  // 		id: 1,
  // 		title: "SRP Federal Credit Union",
  // 		imageUrl: `${FeaturedImages.one}`,
  // 		projectUrl: "SRP",
  // 	},
  // 	{
  // 		id: 2,
  // 		title: "Premier Networx",
  // 		imageUrl: `${FeaturedImages.two}`,
  // 		projectUrl: "Premier Networx",
  // 	},
  // 	{
  // 		id: 3,
  // 		title: "Augusta University Fine Arts Center",
  // 		imageUrl: `${FeaturedImages.three}`,
  // 		projectUrl: "AU FAC",
  // 	},
  // 	{
  // 		id: 4,
  // 		title: "East Central Regional Hospital",
  // 		imageUrl: `${FeaturedImages.four}`,
  // 		projectUrl: "East-Central",
  // 	},
  // 	{
  // 		id: 5,
  // 		title: "Jordan Trotter",
  // 		imageUrl: `${FeaturedImages.five}`,
  // 		projectUrl: "Jordan Trotter",
  // 	},
  // 	{
  // 		id: 6,
  // 		title: "Vocational Technical Magnet High School",
  // 		imageUrl: `${FeaturedImages.six}`,
  // 		projectUrl: "Votech",
  // 	},
  // 	{
  // 		id: 7,
  // 		title: "Augusta Prep Science Building",
  // 		imageUrl: `${FeaturedImages.seven}`,
  // 		projectUrl: "Augusta Prep Science Building",
  // 	},
  // 	{
  // 		id: 8,
  // 		title: "View All Projects",
  // 		imageUrl: null,
  // 		projectUrl: "",
  // 	},
  // ];
  const categories = [
    {
      id: 1,
      title: "5th Street Bridge",
      imageUrl: `${FeaturedImages.one}`,
      projectUrl: "5th Street Bridge",
    },
    {
      id: 2,
      title: "Hyatt House Downtown",
      imageUrl: `${FeaturedImages.two}`,
      projectUrl: "Hyatt House",
    },
    {
      id: 3,
      title: "Goodwill / Helms College",
      imageUrl: `${FeaturedImages.three}`,
      projectUrl: "Goodwill",
    },
    {
      id: 4,
      title: "Trotter Jones Law Firm",
      imageUrl: `${FeaturedImages.four}`,
      projectUrl: "Trotter Jones Law Offices",
    },
    {
      id: 5,
      title: "Market Pavilion at the Plaza",
      imageUrl: `${FeaturedImages.five}`,
      projectUrl: "Market Pavilion At The Plaza",
    },
    {
      id: 6,
      title: "Via Cognitive Health Center",
      imageUrl: `${FeaturedImages.six}`,
      projectUrl: "Via Cognitive Health Center",
    },
    {
      id: 7,
      title: "Heritage Early Learning Academy",
      imageUrl: `${FeaturedImages.seven}`,
      projectUrl: "HELAG",
    },
    {
      id: 8,
      title: "View All Projects",
      imageUrl: null,
      projectUrl: "",
    },
  ];

  return <Directory categories={categories} />;
};

export default FeaturedList;
