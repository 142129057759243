import "./404.styles.scss";

const NoPage = () => {
	return (
		<div className="noPage">
			<h1>404</h1>
			<p>Sorry, looks like we haven't designed any buildings here.</p>
		</div>
	);
};

export default NoPage;
