const FeaturedImages = {
  one: require("../../assets/projects/government/5th Street Bridge/5th-Street-Bridge (1).jpg"),
  two: require("../../assets/projects/hospitality/Hyatt House/Hyatt-House (3).jpg"),
  three: require("../../assets/projects/hospitality/Goodwill/Goodwill (2).jpg"),
  four: require("../../assets/projects/commercial/Trotter Jones Law Offices/Trotter Jones Law Offices (12).jpg"),
  five: require("../../assets/projects/hospitality/Market Pavilion At The Plaza/EvansTownMarket2024_020.jpg"),
  six: require("../../assets/projects/hospitality/Via Cognitive Health Center/0 Via-putting green.jpg"),
  seven: require("../../assets/projects/education/HELAG/Heritage-Early-Learning (6).jpg"),

  eigth: require("../../assets/projects/commercial/taylor-hyundai/t-hyundai-ext-preferred.jpg"),
};

export default FeaturedImages;
